import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';
import { withNonPureBreakpoint } from 'core/components/breakpoint';
import Expert from 'core/components/Expert';

import { Block, Section } from 'core/components/Grid';
import AdWrapper from 'core/components/Ad/AdWrapper';
import EmptyWrapper from 'core/components/EmptyWrapper';
import Lead from 'core/components/Lead';
import Button from 'core/components/Button';
import CommentsCount from 'core/components/CommentsCount';
import Link from 'core/components/Link';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import TopicTopline from 'core/components/TopicTopline';
import Divider from 'core/components/Divider';

import SocialShare from 'site/components/SocialShare';
import {
  Indent,
  TopicTextWrap,
} from 'site/components/Wrappers';

import AuthorOpinionHeader from 'site/components/AuthorOpinionHeader';

import {
  TOPIC_INDENT_LEFT,
} from 'site/constants';

import TopicHeaderMedia from './TopicHeaderMedia';

import { ENCIKLOPEDIYA_SLUG } from 'site/constants.js';

import styles from './topicHeader.styl';

const Sponsored = require('site/components/Ads/Sponsored').default;

const relationships = resolveRelationships(
  ['opinion', 'authors', 'rubric'],
  null,
  {
    authors: [],
  },
);


function TopicHeader(props) {
  const {
    content,
    hideMedia,
    hideCommentsButton,
    isMobile,
    isDesktop,
    theme,
    isGameCompare,
    infinityIndex,
  } = props;

  const {
    id,
    attributes: {
      announce,
      headline,
      alternative_headline: altHeadline,
      link,
      is_comments_disabled: isCommentsDisabled,
      topic_type: topicType,
      is_premium: isPremiumTopic,
    },
  } = content;

  const {
    opinion,
    authors,
    rubric,
  } = relationships(content);

  const isEnciklopediya = rubric.slug === ENCIKLOPEDIYA_SLUG;

  const isUgc = topicType === 'ugc';
  const showAuthorOpinionHeader = isUgc || !!opinion;
  const expert = authors.find(author => author.attributes.type === 'expert');

  const { body: opinionBody } = opinion || {};
  const InnerWrapper = isMobile || !opinion
    ? EmptyWrapper
    : TopicTextWrap;
  const AuthorWrapper = isMobile
    ? EmptyWrapper
    : TopicTextWrap;

  return (
    <AdWrapper bottom={15}>
      <style jsx>{`
        .${styles.title}
          font ${theme.texts.title1.font}

        .${styles.subtitle}
          font-family ${theme.fonts.display}

        .${styles.opinion}
          font 800 20px/20px ${theme.fonts.display}
          color ${theme.colors.hintLight}
          :global(.mobile) &
            font 800 16px/16px ${theme.fonts.display}
      `}</style>
      {!isPremiumTopic && (
        <>
          <InnerWrapper invert>
            <Section>
              <Block
                desktop={opinion ? `${TOPIC_INDENT_LEFT}px` : 0}
                mobile={opinion ? 12 : 0}
              />
              <Block mobile={12}>
                <TopicTopline topic={{ content, infinityIndex }} />
              </Block>
            </Section>
            <Indent top={isMobile ? 20 : 15} />
            <Section>
              <Block
                desktop={opinion ? `${TOPIC_INDENT_LEFT}px` : 0}
                mobile={opinion ? 12 : 0}
              >
                <div className={styles.opinion}>Мнение</div>
              </Block>
              <Block mobile={12}>
                <h1 className={styles.title}>
                  <MarkdownWrapper inline>
                    {headline}
                  </MarkdownWrapper>
                </h1>
                {' '}
                {expert && (
                  <div className={styles.expert}>
                    <Expert content={expert} />
                    <div className={styles.quote} />
                  </div>
                )}
                {(isEnciklopediya && altHeadline) &&
                  <span className={styles.title}>-<br /></span>
                }
                {altHeadline && (
                  <span className={styles.subtitle}>
                    <MarkdownWrapper inline>
                      {altHeadline}
                    </MarkdownWrapper>
                  </span>
                )}
              </Block>
            </Section>
            <Indent bottom={isDesktop ? 15 : 10} />
            <Sponsored />
          </InnerWrapper>
          {showAuthorOpinionHeader && (
            <AuthorWrapper invert>
              <AuthorOpinionHeader
                author={opinion ? opinion.author : authors[0]}
                vertical={isMobile}
              />
              <Indent bottom={isDesktop ? 20 : 15} />
            </AuthorWrapper>
          )}
        </>
      )}
      {!!(opinionBody || announce) && (
        <Indent bottom={isDesktop ? 20 : 16}>
          <Lead>
            <MarkdownWrapper>{opinionBody || announce}</MarkdownWrapper>
          </Lead>
        </Indent>
      )}
      <div className={styles.commentsWrapper}>
        {(!isCommentsDisabled && !hideCommentsButton && !isGameCompare) && (
          <Link to={`${link}/comments`}>
            <div className={styles.comments}>
              <Button size='large'>
                Обсудить
                <span className={styles.commentsCount}>
                  <CommentsCount xid={id} disableIcon />
                </span>
              </Button>
            </div>
          </Link>
        )}
        {!isGameCompare && <SocialShare />}
      </div>
      {isPremiumTopic && <Divider className={styles.divider} />}
      {(!hideMedia && !isGameCompare && !isPremiumTopic) && (
        <Indent top={24} bottom={isMobile ? 24 : 36}>
          <TopicHeaderMedia content={content} />
        </Indent>
      )}
    </AdWrapper>
  );
}

TopicHeader.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  hideMedia: PropTypes.bool,
  hideCommentsButton: PropTypes.bool,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
  isGameCompare: PropTypes.bool,
  infinityIndex: PropTypes.number,
};

export default withNonPureBreakpoint(withTheme(TopicHeader));
