import PropTypes from 'prop-types';
import { Fragment } from 'react';

import StickyPortal from 'core/components/StickyPortal';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { Indent } from 'site/components/Wrappers';
import SelfPromo from 'core/components/SelfPromo';
import { PartnersVertical } from 'site/components/Partners';

import {
  Ad240x400,
  Ad240x400Second,
} from 'site/components/Ads/desktop';

import { VERTICAL_INDENT } from 'site/constants';

export default function InnerSideColumn(props) {
  const {
    hideRnet,
  } = props;

  return (
    <Fragment>
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper bottom={VERTICAL_INDENT}>
          <Ad240x400 />
          <SelfPromo />
          <Indent bottom={VERTICAL_INDENT} />
          {!hideRnet && <PartnersVertical />}
        </AdWrapper>
      </StickyPortal>
      <StickyPortal maxOffset={2400}>
        <AdWrapper bottom={VERTICAL_INDENT}>
          <Ad240x400Second />
        </AdWrapper>
      </StickyPortal>
    </Fragment>
  );
}

InnerSideColumn.propTypes = {
  hideRnet: PropTypes.bool,
};
