import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile, enciklopediya, enciklopediyaMobile } from 'site/themes';

import { HOST } from 'site/constants';


/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig(requestHeaders) {
  const hostName = requestHeaders
    ? resolve({
      'dev': HOST,
      '*': requestHeaders.host,
    }) : HOST;

  const siteUrl = 'https://' + hostName;

  return {
    siteName: 'secretmag',
    host: hostName,
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
      enciklopediya: {
        base: enciklopediya,
        mobile: enciklopediyaMobile,
      },
    },

    coreApisConfig: {
      ugcApi: resolve({
        'dev': 'https://s1.secretmag.ru/ugc/api/v1',
      }),
      bebopApi: resolve({
        'dev': '//s1.secretmag.ru/api/v2',
      }),
    },

    bannersForCapirsMonitor: [
      432425130,
      459973718,
    ],

    isUnityProject: true,

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/secretmag',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: resolve({
      '*': `${siteUrl}/erratum`,
      'staging': `https://${getEnv('SITE_HOST')}/erratum`,
    }),

    schemaOrg: {
      organizationName: 'Секрет Фирмы',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 90,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s — Секрет фирмы',
      defaultTitle: 'Интернет-журнал о бизнесе в России',
      meta: [
        { name: 'description',
          content: 'Издание о бизнесе, экономике, финансах и высоких технологиях, которые обеспечивают развитие экономики и бизнеса в России и в мире.',
        },
      ],
    },

    videoHosting: {
      eagleplatform: {
        account: 'secretmag',
        adTemplateId: 9564,
      },
    },

    ramblerCommentsAppId: resolve({
      'production': 6,
      '*': 194,
    }),

    ads: {
      siteId: '432419980',
    },

    counterIds: {
      tns: { account: 'rambler_ru', tmsec: 'secretmag_total' },
      mediascope: 'rambler_cid1100108-posid2155112/',
      top100: 3089665,
      liveInternet: { ids: ['Lenta', 'gazeta_all'] },
      yandexMetrika: 28725206,
      mailru: 3090300,
      rcm: resolve({
        '*': 'RCM-27BE',
        'production': 'RCM-0657',
      }),
    },

    features: {
      enableRetina: true,
      disableBlurPreview: true,
      enableUgc: false,
      isNewVersionPremiumTopic: true,
      enableFenwick: true,
      enableCapirsMonitor: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': 'C286F-3625C',
        'staging': '12365-C215C',
      }),
      safariWebsitePushID: 'web.secretmag.ru',
      defaultNotificationTitle: '!!!',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    specialLinks: [
      '/about', // чтобы работала хеш навигация
      '/exports/rss.xml',
      '/navalnyi',
      '/shpargalki-dlya-predprinimatelya-sovety-kotorye-pomogut-dobitsya-uspekha.htm',
      '/vechnye-biznesmeny-kto-investiruet-v-prodlenie-zhizni-i-kogda-chelovechestvo-pobedit-smert.htm',
      '/vich-v-rossii.htm',
      '/girlsgirlsgirls.htm',
      '/stalin-prodayot-kak-biznes-zaigryvaet-s-obrazami-sovetskogo-terrora.htm',
      '/mashina-vremeni-dlya-predprinimatelei.htm',
      '/iskusstvennyi-intellekt-na-sluzhbe.htm',
      '/e-commerce-v-oblakakh.htm',
      '/zuby-na-karte-rodiny.htm',
      '/palachi-i-zhertvy-vnutri-odnoi-semi.htm',
      '/15let.htm',
      '/reiting-molodykh-mediamenedzherov-rossii-2016.htm',
      '/coach-not-coach.htm',
      '/losing-my-memory.htm',
      '/vragovi-i-derevnya.htm',
      '/arhipelag-fsin.htm',
      '/reiting-sekret-firmi-2016.htm',
      '/rejting-rossijskih-biznes-angelov-2016.htm',
      '/south-evil.htm',
      '/25-let-biznesu.htm',
      '/rating-mediamanagers-2015.htm',
      '/parallelnyi-mir.htm',
      '/epidemiya-na-kotoruyu-zhalko-deneg-kak-rossiiskie-vlasti-ekonomyat-na-borbe-s-vich.htm',
      '/podopytnye-kroliki.htm',
      '/koshelyok-ili-zhizni.htm',
      '/gadaem-po-knige.htm',
    ],

    spammer: {
      ramblerCrmProjectKey: 'secretmag',
      digestKey: 'product',
      termsUrl: 'https://help.rambler.ru/legal/1706/',
    },

    ramblerId: {
      rname: 'secretmag',
      useAuthRedirect: true,
    },

    socials: [
      { name: 'vkontakte', link: 'https://vk.com/scrtfrm' },
      { name: 'telegram', link: 'https://telegram.me/businesssecrets' },
      { name: 'twitter', link: 'https://twitter.com/scrtmag' },
      { name: 'odnoklassniki', link: 'https://ok.ru/group/53519057158260' },
      { name: 'yandex_dzen', link: 'https://dzen.ru/secretmag?favid=1670' },
    ],
  };
}
