import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { Mobile, Desktop, withBreakpoint } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { ScrollToSiteContext } from 'core/components/ScrollToSite';

import Themer from 'site/components/Themer';
import FooterTop from 'site/components/FooterTop';
import { SideIndent, Indent } from 'site/components/Wrappers';

import { Context } from 'site/components/Ads/desktop';
import { Footer as FootBanner } from 'site/components/Ads/mobile';

import { isEnciklopediya } from 'site/utils';
import {
  ENCIKLOPEDIYA_PATHNAME,
  LIFT_PATHNAME,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

function PageBoroda(props, { amp }) {
  const {
    content,
    isMobile,
    location: { pathname },
    children,
    isLoading,
  } = props;

  if (amp) return children;

  const mainPageEnciklopediya = pathname === ENCIKLOPEDIYA_PATHNAME;
  const disableAds = pathname === LIFT_PATHNAME || mainPageEnciklopediya;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const themeName = isEnciklopediya(pathname) ? 'enciklopediya' : 'dark';

  if (disableAds) {
    return (
      <Fragment>
        {children}
        <Indent top={vertical} />
        {!isLoading &&
          <Themer themeName={themeName}>
            <FooterTop content={content} />
          </Themer>
        }
      </Fragment>
    );
  }

  return (
    <>
      {children}
      <ScrollToSiteContext.Consumer>
        {({ isScrollToSite }) => {
          if (isScrollToSite) return null;
          return (
            <AdWrapper top={vertical}>
              <Desktop>
                <SideIndent>
                  <Context />
                </SideIndent>
              </Desktop>
              <Mobile>
                <FootBanner />
              </Mobile>
              <Indent top={vertical} />
              {!isLoading &&
                <Themer themeName={themeName}>
                  <FooterTop content={content} />
                </Themer>
              }
            </AdWrapper>
          );
        }}
      </ScrollToSiteContext.Consumer>
    </>
  );
}

PageBoroda.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  isLoading: PropTypes.bool,
};

PageBoroda.contextTypes = {
  amp: PropTypes.bool,
};

export default withRouter(withBreakpoint(PageBoroda));
