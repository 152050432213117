import deepmerge from 'core/libs/deepmerge';

import EmptyWrapper from 'core/components/EmptyWrapper';

import LogoScooterMobile from 'site/components/LogoScooterMobile';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import { CardSmall3 } from 'site/cards/CardSmall';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `400 18px/25px ${fonts.display}`,
    },

    body: {
      font: `16px/24px ${fonts.text}`,
    },

    // h1 в топике
    title1: {
      font: `800 24px/26px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    // заголовки второго уровня в топике (не подзаголовок)
    title2: {
      font: `700 20px/25px ${fonts.display}`,
    },

    // большинство тайтлов карточек
    title3: {
      font: `700 18px/26px ${fonts.display}`,
    },

    title4: {
      font: `700 16px/22px ${fonts.display}`,
    },

    // маленькие карточки
    title5: {
      font: `700 16px/22px ${fonts.display}`,
    },

    // главная карточка в черном mjolnir
    title6: {
      font: `700 16px/22px ${fonts.display}`,
    },


    incut: {
      font: `bold 14px/20px ${fonts.text}`,
    },
  };

  const scooter = {
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
    logo: {
      Icon: LogoScooterMobile,
      width: 56,
      height: 24,
    },
    social: {
      margin: '0 0 0 10px',
      size: 27,
      iconSize: 16,
    },
  };

  const shapka = {
    logo: {
      width: 75,
      height: 37,
      marginTop: '0px',
    },
    height: 64,
    padding: `2px ${SIDE_INDENT_MOBILE}px 2px ${SIDE_INDENT_MOBILE}px`,
    topIndent: '0px',
    menu: {
      itemDivider: 'none',
    },
    dropdown: {
      link: {
        font: `700 19px/22px ${fonts.display}`,
      },
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 16px/1.5 ${fonts.text}`,
      },
      info: {
        font: `700 12px/18px ${fonts.text}`,
      },
    },
  };

  const expert = {
    wrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    info: {
      marginLeft: '0',
      alignItems: 'center',
    },
    name: {
      idle: {
        font: `700 16px/20px ${fonts.text}`,
        margin: '10px 0 5px 0',
      },
      hover: {
        font: `700 16px/20px ${fonts.text}`,
        margin: '10px 0 5px 0',
      },
    },
    jobTitle: {
      font: `400 12px/14px ${fonts.text}`,
    },
    label: {
      font: `700 8px/1 ${fonts.text}`,
    },
  };

  const topicFooter = {
    commentButton: {
      topSpacing: VERTICAL_INDENT_MOBILE,
    },
    links: {
      marginLeft: '10px',
      interitemSpacing: 20,
    },
    block: {
      spacing: VERTICAL_INDENT_MOBILE,
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 20px/22px ${fonts.display}`,
    },
    name: {
      font: `bold 14px/21px ${fonts.nuance}`,
    },
    date: {
      font: `normal 14px/21px ${fonts.nuance}`,
    },
  };

  const errorPage = {
    imgWidth: 255,
    title: {
      font: `bold 36px/40px ${fonts.display}`,
    },
    subtitle: {
      font: `18px/24px ${fonts.text}`,
    },
    card: CardSmall3,
  };

  const story = {
    slide: {
      width: 145,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `600 22px/27px ${fonts.display}`,
    },
  };

  const pages = {
    notFound: errorPage,
    sentryFallback: {
      ...errorPage,
      imgWidth: 170,
      card: null,
    },
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const gameCompare = {
    compare: {
      margin: '0 auto',
      progressType: 3,
    },
    leaderBoard: {
      caption: `300 15px/22px ${fonts.text}`,
      ratings: `500 15px/22px ${fonts.text}`,
      modalWindow: {
        color: atoms.colors.primary,
      },
    },
    concidences: {
      font: `400 15px/19px ${fonts.text}`,
      margin: '0 0 15px',
    },
    winner: {
      borderRadius: 0,
      padding: '20px 15px',
    },
    tooltip: {
      scheme: {
        titleFont: `700 18px/29px ${fonts.display}`,
        textFont: `400 16px/23px ${fonts.display}`,
      },
    },
    share: {
      marginTop: 0,
      showBorder: true,
    },
    announce: {
      announceIndent: EmptyWrapper,
    },
  };

  const vikontIncut = {
    borderWidth: '0 0 1px 0',
    padding: '0 0 30px 0',
    font: `700 16px/22px ${fonts.display}`,
  };

  const vikontReadMore = {
    drum: {
      itemWidth: 300,
      indent: 20,
    },
  };

  const login = {
    popup: {
      theme: 'dark',
    },
  };

  const about = {
    headline: {
      font: `bold 24px/26px ${fonts.text}`,
    },
    title2: {
      font: `bold 20px/25px ${fonts.text}`,
    },
  };

  return deepmerge({
    controls: {
      scooter,
      shapka,
      expert,
      smartTeaser,
      topicFooter,
      story,
      storyWidgetsBaseCard,
      commentsPreview,
      gameCompare,
      vikontIncut,
      login,
      vikontReadMore,
      about,
    },
    pages,
    layout,
    texts,
  }, atoms);
}
